export const environment = {
  COMPANY_NAME: "evollo",
  production: true,
  APP_NAME: "QUALITY",
  apiUrl: "https://tokiomarine-quality.api.evollo.cloud",
  accountUrl: "https://tokiomarine-account.api.evollo.cloud",
  integrationUrl: "https://tokiomarine-integration.api.evollo.cloud",
  genAiUrl: 'https://genai-integrator-api.evollo.cloud:8485',
	features: {
		enabledAverageGrade: true,
		enabledChat: false,
		enableQualityDashboard: false,
		enableInteractionSentiment: false,
		enableSupport: false,
		enableFaq: false,
		homepage: '/interactions',
		cycleAsContactDate: false, // false = data de monitoria || true = data de contato
		enableInteractionMonitorCycle: true,
		aiEnabled: true,
		aiInsightsEnabled: true,
		aiInteractionEnabled: true,
		trainingUrl: null
	},
  apps: {
    id: "https://tokiomarine.id.evollo.com.br",
    admin: "https://tokiomarine.admin.evollo.com.br",
  },
};
